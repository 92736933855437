<template>
  <div class="manage-cashier">
    <!-- Manage tabs -->
    <manage-tabs class="pb-3" />

    <!-- Filter tabs -->
    <filter-cook
      :filter="filter"
      @filter="filterData"
    />

    <!-- List -->
    <list-cook
      v-loading="callingAPI"
      :data="dishCarts"
      @update="handleUpdateReceipt"
    />

    <!-- Pagination -->
    <el-row type="flex" align="middle" justify="end" class="pagination mt-5">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      />
    </el-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { getReceipts, updateReceipt } from '@/services/receipt'
import ManageTabs from './components/Tabs.vue'
import FilterCook from './components/cook/FilterCook'
import ListCook from './components/cook/ListCook'
import { getDishSubscription } from '@/services/dish-subcription'

export default {
  name: 'ManageCashier',
  components: {
    ManageTabs,
    FilterCook,
    ListCook
  },
  data() {
    return {
      callingAPI: false,
      page: 1,
      total: 100,
      limit: 10,
      dishCarts: [],
      filter: {
        placeId: '',
        createdAt: dayjs().format('YYYY-MM-DDT00:00:00'),
        searchText: ''
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        page: this.page,
        per_page: this.limit,
        search_text: this.filter.searchText,
        locale: this.language,
        place_ids: this.filter.placeId,
        executed_status: this.$route.query.status || 'new',
        created: this.filter.createdAt
      }
    }
  },
  watch: {
    '$route.query.status': 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    filterData(data) {
      if (data) {
        this.filter = data
      }
      this.loadData()
    },
    loadData() {
      this.callingAPI = true
      getReceipts(this.params).then(async (response) => {
        const dishCarts = response.result
        this.total = response.total
        const allRequests = []
        dishCarts.forEach((dishCart, index) => {
          const request = getDishSubscription({ receipt_id: dishCart.id, embedded: 'dish' })
            .then((res) => {
              dishCarts[index].dishes = res.result
            })
            .catch(() => {})
          allRequests.push(request)
        })
        await Promise.all(allRequests)
        this.dishCarts = dishCarts
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },

    handleUpdateReceipt(data) {
      this.callingAPI = true
      updateReceipt(data).then(() => {
        this.loadData()
        setTimeout(() => {
          this.$notify.success({
            title: 'Thông báo !',
            message: 'Đổi trạng thái thành công !'
          })
        }, 500)
      }).catch(() => {
        this.callingAPI = false
      })
    },

    handleChangeSize(size) {
      this.limit = size
      this.loadData()
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
