<template>
  <el-row class="manage-tabs">
    <el-button
      v-for="status in manageStatus"
      :key="status.key"
      round
      :plain="currentCartStatus !== status.value"
      :type="status.type"
      class="mb-2"
      @click="handleChangeTab(status)"
    >{{ status.label }}</el-button>
  </el-row>
</template>

<script>
import DISH from '@/data/dish'

export default {
  name: 'ManageTabs',
  components: {},
  data() {
    return {
      manageStatus: []
    }
  },
  computed: {
    currentCartStatus() {
      const status = this.manageStatus.find((s) => s.value === (this.$route.query.status || ''))
      if (this.$route.name === 'ManageCook') {
        return status ? status.value : 'new'
      }
      return status ? status.value : ''
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (this.$route.name === 'ManageCook') {
        this.manageStatus = DISH.MANAGE_CART_STATUS.slice(1, 4)
        return
      }
      this.manageStatus = DISH.MANAGE_CART_STATUS
    },
    handleChangeTab(status) {
      this.$router.replace({ name: this.$route.name, query: { status: status.value }})
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-tabs {
  width: 100%;
  overflow: auto;
}
</style>
